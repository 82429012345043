<template>
  <div class="mt-xl-16 mt-10 pp-ckt">
    <v-container>
      <h2 class="pp-title mb-7 pt-16">
        Checking out your basket.
        <br class="hidden-sm-and-up" />
        Please wait...
      </h2>
      <v-sheet class="pa-10 rounded-xl">
        <LearningPlusLogo :width="150" />
        <v-skeleton-loader
            type="
          article: heading, paragraph,
          card-avatar: image, list-item-avatar,
          card-heading: heading,
          date-picker: list-item, card-heading,
          divider, date-picker-options, date-picker-days, actions,
          heading: heading"
        ></v-skeleton-loader>
      </v-sheet>
    </v-container>

  </div>

</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { matomoEcommerce } from "@/mixin/matomoEcommerce";
import LearningPlusLogo from "@/components/logos/LearningPlusLogo";

export default {
  name: "AutoCheckout",
  components: {LearningPlusLogo},
  mixins: [matomoEcommerce],
  computed: {
    ...mapGetters(['basket']),
  },
  methods: {
    ...mapActions(['checkout']),
  },
  async beforeMount() {
    for (const item of this.basket) {
      this.matomoAddEcommerceItem(item._id, item.name, "", item.priceInUnits, item.quantity);
    }
    this.matomoTrackEcommerceCartUpdate(this.basketValue);
    try {
      await this.checkout(this.basket);
    } catch (stripeError) {
      console.error(stripeError.message);
      this.errorMessage = true;
    }
  },
};
</script>

<style scoped>
.pp-ckt {
  left: 50%; position:absolute;
  -ms-transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}

</style>

